/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2023-11-30 22:09:48
 * @LastEditTime: 2023-12-08 18:19:15
 * @Description: 
 */
import React, { useState, useEffect } from 'react';
import '../css/Mortage.css';
import TopButton2 from './TopButton2';
import { useFile } from '../storage/FileContext';
import { loadImageFromStorage ,loadImageFromStorageStep3} from '../storage/ImageStorage';



function Mortage({ data, setData, backToStart }) {


  const { file, setFile } = useFile();
  const [filePreview, setFilePreview] = useState(loadImageFromStorage());
  const [apiResponse, setApiResponse] = useState(null);
  const { file2, setFile2 } = useFile();
  const { file3, setFile3 } = useFile();
  const [filePreview2, setFilePreview2] = useState(loadImageFromStorageStep3());
  const [filePreview3, setFilePreview3] = useState(loadImageFromStorageStep3());
  const [copyPhoneToBank, setCopyPhoneToBank] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFilePreview("/images/buttons/loading.gif");
    // Read the selected file and set the file preview
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        console.log(apiResponse);
      };
      reader.readAsDataURL(selectedFile);

      try {
        const formData = new FormData();
        formData.append('file', selectedFile);

        const response = await fetch('https://ocr3.kinfaat.com/upload', {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const res = await response.json();
        //console.log(res);
        if (res.statusCode === 1) {
          console.log(res.data);
          setData({
            ...data, name: res.data.nameCn, age: res.data.age, idNumber: res.data.idNumber,
            sex: res.data.sex, nameEn: res.data.nameEn,
            birthDate: res.data.birthDate, idImageUrl: res.fileUrl

          });
        }

        if (res.statusCode === 2 || res.statusCode === 3) {
          console.log(res);
          setData({
            ...data, idImageUrl: res.fileUrl
          });
        }
        setApiResponse(res);
      } catch (error) {
        console.error('Error:', error);
        setApiResponse({ statusCode: 0, message: 'Error uploading image' });
      } finally {
        reader.onload = (event) => {
          setFilePreview(event.target.result);
        };
        reader.readAsDataURL(selectedFile);
      }
    }
  }

  const handleAddressFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    setFile2(selectedFile);
    setFilePreview2("/images/buttons/loading.gif");
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        //console.log(apiResponse);
      };
      reader.readAsDataURL(selectedFile);

      try {
        const formData = new FormData();
        formData.append('file', selectedFile);

        const response = await fetch('https://ocr3.kinfaat.com/uploadOnly', {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const res = await response.json();
        //console.log(res);
        setData({
          ...data, addressImageUrl: res.fileUrl
        });
      } catch (error) {
        console.error('Error:', error);
      } finally {
        reader.onload = (event) => {
          setFilePreview2(event.target.result);
        };
        reader.readAsDataURL(selectedFile);
      }
    }
  }

  const handleProofFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    setFile3(selectedFile);
    setFilePreview3("/images/buttons/loading.gif");
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        //console.log(apiResponse);
      };
      reader.readAsDataURL(selectedFile);

      try {
        const formData = new FormData();
        formData.append('file', selectedFile);

        const response = await fetch('https://ocr3.kinfaat.com/uploadOnly', {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const res = await response.json();
        //console.log(res);
        setData({
          ...data, mortageImageUrl: res.fileUrl
        });
      } catch (error) {
        console.error('Error:', error);
      } finally {
        reader.onload = (event) => {
          setFilePreview3(event.target.result);
        };
        reader.readAsDataURL(selectedFile);
      }
    }
  }



  const handleChoiceChange = (choice) => {
    setData({ ...data, mortageOption: choice });
  };

  const isNextButtonEnabled = data.mortageOption;

  const handleNextClick = () => {
    if (isNextButtonEnabled) {

      //nextStep();
    } else {
      alert("Please complete all fields and upload a file before proceeding.");
    }
  }

  const handleTextChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  const handleCheckboxChange = () => {
    setCopyPhoneToBank(!copyPhoneToBank); // Toggle the checkbox state
    setData({ ...data, bank: data.phoneNumber });
  };




  return (

    <div className="containerMort">
      <form className="step3-form">
        <section className="avatars">
          <img src="/images/step1/FATY.png" alt="Avatar 1" className="avatar" />
          <img src="/images/step1/HOMY.png" alt="Avatar 2" className="avatar" />
        </section>

        <TopButton2 data={data} setData={setData} backToStart={backToStart} />


        <div className="descriptions">
          <p className="description-text"></p>
        </div>

        <div className="radio-option1">
          <input type="radio" id="option1" name="radio-group" value="物業貸款" checked={data.mortageOption === "物業貸款"}
            onChange={() => handleChoiceChange("物業貸款")} />
          <label htmlFor="option1" className="radio-label">物業貸款</label>
        </div>

        <div className="radio-option2">
          <input type="radio" id="option2" name="radio-group" value="物業一按" checked={data.mortageOption === "物業一按"}
            onChange={() => handleChoiceChange("物業一按")} />
          <label htmlFor="option2" className="radio-label">物業一按</label>
        </div>

        <div className="radio-option3">
          <input type="radio" id="option3" name="radio-group" value="物業二按" checked={data.mortageOption === "物業二按"}
            onChange={() => handleChoiceChange("物業二按")} />
          <label htmlFor="option3" className="radio-label">物業二按</label>
        </div>

        <div className="radio-option4">
          <input type="radio" id="option4" name="radio-group" value="業主 P LOAN" checked={data.mortageOption === "業主 P LOAN"}
            onChange={() => handleChoiceChange("業主 P LOAN")} />
          <label htmlFor="option4" className="radio-label">業主 P LOAN</label>
        </div>


        <div className="radio-option5">
          <input type="radio" id="option5" name="radio-group" value="居屋 P LOAN" checked={data.mortageOption === "居屋 P LOAN"}
            onChange={() => handleChoiceChange("居屋 P LOAN")} />
          <label htmlFor="option5" className="radio-label">居屋 P LOAN</label>
        </div>

        <div className="descriptions">
          <p className="description-text">年期</p>
        </div>

        <div className="year-input-container">
          <div className="year-field">
            <input
              autoComplete="false"
              required="required"
              name="mortageYear"
              type="number"
              pattern="[0-9]*"
              value={data.mortageYear}
              onChange={handleTextChange} />
            <label htmlFor="input1">年</label>
          </div>
        </div>

        <div className="mortage-content-container">
          <div className='mortage-part2-container'>
          <div className="descriptions2">
            <p className="description-text2">身份證</p>
          </div>
          <div className="mortage-s3-upload-container">
            <input type="file" id="file-upload" accept="image/*;capture=camera" capture hidden onChange={handleFileChange} />
            <label htmlFor="file-upload" className="custom-file-uploadm1">
              {file === null ? (<img src="/images/step2/uploadArea.png" alt="Upload" />) : (<img src={filePreview} alt="Upload" />)}
            </label>
            <div id="preview-area"></div>
          </div>
          </div>
         
          <div className="mortage-image-container">
            <img src="/images/step1/berry.png" alt="Descriptive Alt Text" />
          </div>
        </div>

        <div className="input-container">
            <div className="input-row">
              <div className="input-field1">
                <label htmlFor="input1">中文姓名</label>
                <input type="text" id="input1" name="name" value={data.name} autoComplete='true'
                  onChange={handleTextChange} />
              </div>
              <div className="input-field2">
                <label htmlFor="input2">英文姓名</label>
                <input type="text" id="input2" name="nameEn" value={data.nameEn} autoComplete='true'
                  onChange={handleTextChange} />
              </div>
            </div>


            <div className="input-row">
              <div className="input-field3">
                <label htmlFor="input3">身份證號碼</label>
                <input type="text" id="idNumber" name="idNumber" value={data.idNumber} autoComplete='true'
                  onChange={handleTextChange} />
              </div>
              <div className="input-field4">
                <label htmlFor="input4">出生日期</label>
                <input type="date" id="birthDate" name="birthDate" value={data.birthDate} autoComplete='true'
                  onChange={handleTextChange} />
              </div>
            </div>
          </div>

          <div className="mortage-content-container-2">
          <div className='mortage-part2-container-a'>
          <div className="descriptionsm2">
            <p className="description-text-address">住址證明</p>
          </div>
          <div className="mortage-s3-upload-container2"  >
            <input type="file" id="file-upload2" accept="image/*;"  hidden onChange={handleAddressFileChange} />
            <label htmlFor="file-upload2" className="custom-file-uploadm2">
              {file2 === null ? (<img src="/images/mortage/uploadAddress.png" alt="Upload" />) : (<img src={filePreview2} alt="Upload" />)}
            
            <div id="preview-area"></div></label>
          </div>
          </div>
         
          <div className='mortage-part2-container-b'>
          <div className="descriptionsm2">
            <p className="description-text-proof">樓按供款紀錄</p>
          </div>
          <div className="mortage-s3-upload-container3">
            <input type="file" id="file-upload3" accept="image/*" hidden onChange={handleProofFileChange} />
            <label htmlFor="file-upload3" className="custom-file-uploadm2">
              {file3 === null ? (<img src="/images/mortage/uploadProof.png" alt="Upload" />) : (<img src={filePreview3} alt="Upload" />)}
            </label>
            <div id="preview-area"></div>
          </div>
          </div>
          </div>
      </form>

      <div className="phone-input-container">
        <div className="phone-row phone-field1">
          <label htmlFor="input1">WhatsApp 電話*</label>
          <input
            autoComplete="false"
            required="required"
            name="phoneNumber"
            type="number"
            pattern="[0-9]*"
            value={data.phoneNumber}
            onChange={handleTextChange} />
        </div>
      </div>

      <div className="downArrow-image-container">
        <img src="/images/buttons/down.png" alt="Descriptive Alt Text" />
      </div>

      <div className="fps-container">

        <div className="copy-image-container">
          <img src="/images/buttons/copy.png" alt="Descriptive Alt Text" onClick={handleCheckboxChange} />
        </div>

        <div className="rightArrow-image-container">
          <img src="/images/buttons/right.png" alt="Descriptive Alt Text" />
        </div>

        <div className="fps-input-container">
          <div className="fps-row fps-field1">
            <label htmlFor="input1">FPS 轉數快資料*</label>
            <input
              autoComplete="false"
              required="required"
              type="text"
              name="bank"
              value={data.bank}
              onChange={handleTextChange}
            />
          </div>
        </div>

      </div>

      <footer className="footer">
        <div className="footer-left">
          <a href="https://wa.me/97733938">
            <img src="/images/buttons/ws.png" alt="Whastapp" className="footer-button-image" />
          </a>
        </div>
        <div className="footer-right">
          {isNextButtonEnabled ? <img src="/images/buttons/next.png" alt="Next" className="footer-button-image" onClick={handleNextClick} /> : <img src="/images/buttons/next(dim).png" alt="Next" className="footer-button-image" />}
        </div>
      </footer>
    </div>


  );
}

export default Mortage;
