import React, { useState, useEffect } from 'react';
import { useFile } from '../storage/FileContext';
import '../css/Step4.css';
import '../css/Step4Record.css';
import '../css/Step4Upload.css';
import { saveImageToStorageStep4, loadImageFromStorageStep4 } from '../storage/ImageStorage';
import { saveChoiceToStorageStep4, loadChoiceFromStorageStep4 } from '../storage/ChoiceStorage';

function Step4({ data, setData, nextStep, prevStep }) {


  const [selectedChoice, setSelectedChoice] = useState(loadChoiceFromStorageStep4() || '');
  const { file4, setFile4 } = useFile();
  const [filePreview, setFilePreview] = useState(loadImageFromStorageStep4());
  //console.log(data);
  useEffect(() => {
    saveImageToStorageStep4(file4);
  }, [file4])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleTextChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  const handleChoiceChange = (choice) => {
    setSelectedChoice(choice);
    setData({ ...data, step4Option: choice });
    saveChoiceToStorageStep4(choice);
  };

  const handleChoiceChange2 = (choice) => {
    setData({ ...data, step4Option2: choice });
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    setFile4(selectedFile);
    setFilePreview("/images/buttons/loading.gif");
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        //console.log(apiResponse);
      };
      reader.readAsDataURL(selectedFile);

      try {
        const formData = new FormData();
        formData.append('file', selectedFile);

        const response = await fetch('https://ocr3.kinfaat.com/uploadOnly', {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const res = await response.json();
        //console.log(res);
        setData({
          ...data, inComeImageUrl: res.fileUrl
        });
      } catch (error) {
        console.error('Error:', error);
      } finally {
        reader.onload = (event) => {
          setFilePreview(event.target.result);
        };
        reader.readAsDataURL(selectedFile);
      }
    }
  }


  const handleNextClick = () => {
    if (isNextButtonEnabled) {
      saveChoiceToStorageStep4(selectedChoice);
      nextStep();
    } else {
      alert("Please complete all fields and upload a file before proceeding.");
    }
  }

  //const isNextButtonEnabled = selectedChoice && file4 && data.job;
  const isNextButtonEnabled = data.step4Option && data.companyName && data.companyPhone && data.companyPosition && data.companyIncome && data.step4Option2;



  return (
    <div className="container">

      <div className="descriptions">
        <p className="description-text">就業情況</p>
      </div>

      <form>
        <div className="radio-selection-container">
          <div className="job-options">
            <div className="job-optionJ1">
              <input type="radio" id="optionJ1" name="job" value="受僱" checked={data.step4Option === "受僱"}
                onChange={() => handleChoiceChange("受僱")} />
              <label htmlFor="optionJ1" className="job-label">受僱</label>
            </div>
            <div className="job-optionJ2">
              <input type="radio" id="optionJ2" name="job" value="自僱" checked={data.step4Option === "自僱"}
                onChange={() => handleChoiceChange("自僱")} />
              <label htmlFor="optionJ2" className="job-label">自僱</label>
            </div>
          </div>
        </div>
      </form>

      <div className="job-input-container">
        <div className="job-row job-field1">
          <label htmlFor="input1">公司名稱*</label>
          <input
            type="text"
            id="companyName"
            value={data.companyName}
            required="required"
            name="companyName"
            onChange={handleTextChange} />
        </div>
        <div className="job-row job-field2">
          <label htmlFor="input2">公司電話*</label>
          <input
            id="companyPhone"
            value={data.companyPhone}
            required="required"
            name="companyPhone"
            type="number"
            pattern="[0-9]*"
            onChange={handleTextChange} />
        </div>
        <div className="job-row job-field3">
          <label htmlFor="input3">職位*</label>
          <input
            id="companyPosition"
            type="text"
            value={data.companyPosition}
            required="required"
            name="companyPosition"
            onChange={handleTextChange} />
        </div>
        <div className="job-row job-field4">
          <label htmlFor="input4">每月收入*</label>
          <input name="companyIncome"
            id="income"
            value={data.companyIncome}
            required="required"
            type="number"
            pattern="[0-9]*"
            onChange={handleTextChange} />
        </div>

        <div className="records">
          <p className="records-text">破產 / 債務重組記錄</p>
          <p className="records-terms">你有否曾經或正處在辦理破產或</p>
          <p className="records-terms">或個人自願安排債務重組或債務舒緩計劃？</p>
        </div>

        <form>
          <div className="records-selection-container">
            <div className="records-options">
              <div className="records-optionR1">
                <input type="radio" id="optionR1" name="record" value="是" checked={data.step4Option2 === "是"}
                  onChange={() => handleChoiceChange2("是")} />
                <label htmlFor="optionR1" className="record-label">是</label>
              </div>
              <div className="records-optionR2">
                <input type="radio" id="optionR2" name="record" value="否" checked={data.step4Option2 === "否"}
                  onChange={() => handleChoiceChange2("否")} />
                <label htmlFor="optionR2" className="record-label">否</label>
              </div>
            </div>
          </div>
        </form>


        <div className="income-container">
          <div className="income-upload">
            <div className="descriptions-income">
              <p className="description-income">入息證明</p>
            </div>
            <div className="income-upload-container">
              <input type="file" id="income-file-upload" accept="image/*" hidden onChange={handleFileChange} />
              <label htmlFor="income-file-upload" className="income-file-upload">
                {file4 === null ? (<img src="/images/step4/upload.png" alt="Upload" />) : (<img src={filePreview} alt="Upload" />)}
              </label>
              <div id="preview-area"></div>
            </div>
          </div>

          <div className="step4-image-container">
            <img src="/images/step4/homy.png" alt="Descriptive Alt Text" />
          </div>
        </div>
      </div>

      <footer className="footer">
        <div className="footer-left">
          <a href="https://wa.me/97733938">
            <img src="/images/buttons/ws.png" alt="Whastapp" className="footer-button-image" />
          </a>
        </div>
        <div className="footer-right">
          <img src="/images/buttons/back.png" alt="Back" className="footer-button-image" onClick={prevStep} />
          {isNextButtonEnabled ? <img src="/images/buttons/next.png" alt="Next" className="footer-button-image" onClick={handleNextClick} /> : <img src="/images/buttons/next(dim).png" alt="Next" className="footer-button-image" />}
        </div>
      </footer>
    </div >
  );
}

export default Step4;
