import React, { useState, useEffect } from 'react';
import '../css/Step2.css';
import { useFile } from '../storage/FileContext';
import { saveImageToStorage, loadImageFromStorage } from '../storage/ImageStorage';

function Step2({ nextStep, data, setData, prevStep }) {

  const { file, setFile } = useFile();
  const [filePreview, setFilePreview] = useState(loadImageFromStorage());
  const [apiResponse, setApiResponse] = useState(null);

  useEffect(() => {
    saveImageToStorage(file);
  }, [file]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    //setFilePreview("https://i.gifer.com/ZKZg.gif");
    setFilePreview("/images/buttons/loading.gif");
    // Read the selected file and set the file preview
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        console.log(apiResponse);
      };
      reader.readAsDataURL(selectedFile);

      try {
        const formData = new FormData();
        formData.append('file', selectedFile);

        const response = await fetch('https://ocr3.kinfaat.com/upload', {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const res = await response.json();
        //console.log(res);
        if (res.statusCode === 1) {
          console.log(res.data);
          setData({
            ...data, name: res.data.nameCn, age: res.data.age, idNumber: res.data.idNumber,
            sex: res.data.sex, nameEn: res.data.nameEn,
            birthDate: res.data.birthDate, idImageUrl: res.fileUrl

          });
        }

        if (res.statusCode === 2 || res.statusCode === 3) {
          console.log(res);
          setData({
            ...data, idImageUrl: res.fileUrl
          });
        }
        setApiResponse(res);
      } catch (error) {
        console.error('Error:', error);
        setApiResponse({ statusCode: 0, message: 'Error uploading image' });
      } finally {
        reader.onload = (event) => {
          setFilePreview(event.target.result);
        };
        reader.readAsDataURL(selectedFile);
      }
    }
  }


  const handleTextChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const isNextButtonEnabled = file && data.name && data.nameEn && data.idNumber && data.birthDate;

  const handleNextClick = () => {
    if (isNextButtonEnabled) {
      nextStep();
    } else {
      alert("Please complete all fields and upload a file before proceeding.");
    }
  }

  return (

    <div className="containerStep2">
      <section className="avatars">
        <img src="/images/step2/pear.png" alt="Avatar 1" className="avatar" />
        <img src="/images/step2/cat.png" alt="Avatar 2" className="avatar" />
      </section>

      <form className="step2-form">
        <div className="descriptions2">
          <p className="description-text2">身份證</p>
        </div>
        <div className="s3-upload-container">
          <input type="file" id="file-upload" accept="image/*;capture=camera" capture hidden onChange={handleFileChange} />
          <label htmlFor="file-upload" className="custom-file-uploads2">
            {file === null ? (<img src="/images/step2/uploadArea.png" alt="Upload" />) : (<img src={filePreview} alt="Upload" />)}
          </label>
          <div id="preview-area"></div>
        </div>

        <div className="input-container">

          <div className="input-row">
            <div className="input-field1">
              <label htmlFor="input1">中文姓名</label>
              <input type="text" id="input1" name="name" value={data.name} autoComplete='true'
                onChange={handleTextChange} />
            </div>
            <div className="input-field2">
              <label htmlFor="input2">英文姓名</label>
              <input type="text" id="input2" name="nameEn" value={data.nameEn} autoComplete='true'
                onChange={handleTextChange} />
            </div>
          </div>


          <div className="input-row">
            <div className="input-field3">
              <label htmlFor="input3">身份證號碼</label>
              <input type="text" id="idNumber" name="idNumber" value={data.idNumber} autoComplete='true'
                onChange={handleTextChange} />
            </div>
            <div className="input-field4">
              <label htmlFor="input4">出生日期</label>
              <input type="date" id="birthDate" name="birthDate" value={data.birthDate} autoComplete='true'
                onChange={handleTextChange} />
            </div>
          </div>
        </div>



      </form>

      <footer className="footer">
        <div className="footer-left">
          <a href="https://wa.me/97733938">
            <img src="/images/buttons/ws.png" alt="Whastapp" className="footer-button-image" />
          </a>
        </div>
        <div className="footer-right">
          <img src="/images/buttons/back.png" alt="Back" className="footer-button-image" onClick={prevStep} />
          {isNextButtonEnabled ? <img src="/images/buttons/next.png" alt="Next" className="footer-button-image" onClick={handleNextClick} /> : <img src="/images/buttons/next(dim).png" alt="Next" className="footer-button-image" />}
        </div>
      </footer>
    </div>


  );
}

export default Step2;