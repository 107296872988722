// ImageStorage.js
export function saveImageToStorage(file) {
  if (file) {
    localStorage.setItem('uploadedImage', URL.createObjectURL(file));
  }
}

export function loadImageFromStorage() {
  return localStorage.getItem('uploadedImage');
}

export function saveImageToStorageStep3(file) {
  if (file) {
    localStorage.setItem('uploadedImageStep3', URL.createObjectURL(file));
  }
}

export function loadImageFromStorageStep3() {
  return localStorage.getItem('uploadedImageStep3');
}

export function saveImageToStorageStep4(file) {
  if (file) {
    localStorage.setItem('uploadedImageStep4', URL.createObjectURL(file));
  }
}

export function loadImageFromStorageStep4() {
  return localStorage.getItem('uploadedImageStep4');
}