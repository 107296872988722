/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2023-11-30 15:32:24
 * @LastEditTime: 2023-11-30 16:41:37
 * @Description: 
 */
import React, { useState } from 'react';
import '../css/Popup.css'; // Import your CSS file

function Popup() {
  const [isOpen, setIsOpen] = useState(true);

  const closePopup = () => {
    setIsOpen(false);
  };

  return (
    isOpen && (


      <div className="popup">
        <div className="popup-content">
          <img src="/images/popup.png" alt="Popup" />
          <span className="close-button" onClick={closePopup}>&times;</span>
        </div>
      </div>
    )
  );
}

export default Popup;
