/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2023-11-03 16:25:36
 * @LastEditTime: 2023-11-30 16:42:02
 * @Description: 
 */
import React, {  useEffect } from 'react';
import '../css/Step6.css';

function Step6({ data, submitForm, nextStep, prevStep }) {

  //console.log(data);




  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  // Display a summary of the user's selections and uploaded files
  return (
    <div className="container">
      <div className="s6descriptions">
        <p className="s6description-text-a"><font className="purpleText">你慳時</font> <font className="greenText">我種樹</font></p>
      </div>

      <div className="finished-image-container">
        <img src="/images/step6/finished.png" alt="Descriptive Alt Text" />
      </div>

      <div className="s6descriptions2">
        <p className="s6description-text-2b">時間就是金錢!!!</p>
        <p className="s6description-text-2g">閣下在180秒之內完成申請，</p>
        <p className="s6description-text-2p">本公司將為您捐出</p>
        <p className="s6description-text-2p">每秒$10給環保機構</p>
        <br /><br />
        <p className="s6description-text-2p">如閣下面臨緊急資金需要，</p>
        <p className="s6description-text-2p">請按左下角<a className="wtpLink" href="https://wa.me/97733938">WhatsApp</a>聯絡</p>
        <p className="s6description-text-2p">客服將為您特快處理。</p>
      </div>
      <div className="finished-image-container2">
        <img src="/images/step6/badguy.png" alt="Descriptive Alt Text" />
      </div>


      <footer className="footer">
        <div className="footer-left">
          <a href="https://wa.me/97733938">

            <img src="/images/buttons/ws.png" alt="Whastapp" className="footer-button-image" />

          </a>
        </div>
        <div className="footer-right">
          <img src="/images/buttons/back.png" alt="Back" className="footer-button-image" onClick={prevStep} />
        </div>
      </footer>
    </div>
  );
}

export default Step6;