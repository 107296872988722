/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2023-11-03 16:25:36
 * @LastEditTime: 2023-11-06 18:38:56
 * @Description: 
 */
import React, { useEffect, useState } from 'react';
import '../css/Step5.css';

function Step5({ data, setData, nextStep, prevStep }) {
  const handleTextChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  const [copyPhoneToBank, setCopyPhoneToBank] = useState(false);

  const handleNextClick = () => {
    if (isNextButtonEnabled) {
      postToAPI();

    } else {
      alert("Please complete all fields before proceeding.");
    }
  }

  const handleCheckboxChange = () => {
    setCopyPhoneToBank(!copyPhoneToBank); // Toggle the checkbox state
    setData({ ...data, bank: data.phoneNumber });
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const isNextButtonEnabled = data.bank && data.phoneNumber;


  //API Start
  const formData = new FormData();
  for (const key in data) {
    formData.append(key, data[key]);
  }

  const postToAPI = () => {
    fetch('https://3minscashapi.kinfaat.com/v1/newAppl',
      {
        method: 'POST',
        body: formData, // Include the FormData object with JSON and images
      })
      .then((response) => {
        if (response.ok) {
          nextStep();
        } else {
          throw new Error('API request failed');
        }
      })
      .then((responseData) => {
        console.log('API response:', responseData);
        // Handle success response
      })
      .catch((error) => {
        console.error('Error sending data to API:', error);
        // Handle error
      });
  };
  //API Stop







  return (
    <div className="container">

      <div className="phone-input-container">
        <div className="phone-row phone-field1">
          <label htmlFor="input1">WhatsApp 電話*</label>
          <input
            autoComplete="false"
            required="required"
            name="phoneNumber"
            type="number"
            pattern="[0-9]*"
            value={data.phoneNumber}
            onChange={handleTextChange} />
        </div>
      </div>

      <div className="downArrow-image-container">
        <img src="/images/buttons/down.png" alt="Descriptive Alt Text" />
      </div>

      <div className="fps-container">

        <div className="copy-image-container">
          <img src="/images/buttons/copy.png" alt="Descriptive Alt Text" onClick={handleCheckboxChange} />
        </div>

        <div className="rightArrow-image-container">
          <img src="/images/buttons/right.png" alt="Descriptive Alt Text" />
        </div>

        <div className="fps-input-container">
          <div className="fps-row fps-field1">
            <label htmlFor="input1">FPS 轉數快資料*</label>
            <input
              autoComplete="false"
              required="required"
              type="text"
              name="bank"
              value={data.bank}
              onChange={handleTextChange}
            />
          </div>
        </div>

      </div>







      <div className="step5-image-container">
        <img src="/images/step5/finish.png" alt="Descriptive Alt Text" />
      </div>

      <footer className="footer">
        <div className="footer-left">
          <a href="https://wa.me/97733938">
            <img src="/images/buttons/ws.png" alt="Whastapp" className="footer-button-image" />
          </a>
        </div>
        <div className="footer-right">
          <img src="/images/buttons/back.png" alt="Back" className="footer-button-image" onClick={prevStep} />
          {isNextButtonEnabled ? <img src="/images/buttons/next.png" alt="Next" className="footer-button-image" onClick={handleNextClick} /> : <img src="/images/buttons/next(dim).png" alt="Next" className="footer-button-image" />}
        </div>
      </footer>
    </div>
  );
}

export default Step5;
