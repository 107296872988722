/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2023-11-16 21:31:46
 * @LastEditTime: 2023-12-08 18:11:58
 * @Description: 
 */
import React, { createContext, useContext, useState } from 'react';

const FileContext = createContext();

export function FileProvider({ children }) {
  const [file, setFile] = useState(null);
  const [file3, setFile3] = useState(null);
  const [file2, setFile2] = useState(null);
  const [file4, setFile4] = useState(null);

  return (
    <FileContext.Provider value={{ file, setFile, file2, setFile2,file3, setFile3, file4, setFile4 }}>
      {children}
    </FileContext.Provider>
  );
}

export function useFile() {
  return useContext(FileContext);
}

