/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2023-11-30 15:32:43
 * @LastEditTime: 2023-11-30 22:15:55
 * @Description: 
 */
import React from 'react';
import '../css/TopButton.css'; // Import your CSS file

function TopButton({ nextStep, data, setData ,jumpToMortage}) {
  const loanButton = (e) => {
    setData({
      ...data, appType: "PLoan"
    });
console.log(data);
  };


  const mortageButtom = (e) => {
    setData({
      ...data, appType: "Mortgage"
    });
    jumpToMortage();
    console.log(data);
  };

  return (
    <div className="topButton">
      <li className="active" onClick={loanButton}>私人貸款 P LOAN</li>
      <li className="right" onClick={mortageButtom}>物業貸款</li>
    </div>
  );
}

export default TopButton;
