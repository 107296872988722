import React, { useState, useEffect } from 'react';
import '../css/Step3.css';
import { useFile } from '../storage/FileContext';
import { saveImageToStorageStep3, loadImageFromStorageStep3 } from '../storage/ImageStorage';
import { saveChoiceToStorageStep3, loadChoiceFromStorageStep3 } from '../storage/ChoiceStorage';


function Step3({ data, setData, nextStep, prevStep }) {
  //const [selectedOption, setSelectedOption] = useState('');
  const [selectedChoice, setSelectedChoice] = useState(loadChoiceFromStorageStep3() || '');
  const { file2, setFile2 } = useFile();
  const [filePreview, setFilePreview] = useState(loadImageFromStorageStep3());

  useEffect(() => {
    saveImageToStorageStep3(file2);
  }, [file2]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    setFile2(selectedFile);
    setFilePreview("/images/buttons/loading.gif");
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        //console.log(apiResponse);
      };
      reader.readAsDataURL(selectedFile);

      try {
        const formData = new FormData();
        formData.append('file', selectedFile);

        const response = await fetch('https://ocr3.kinfaat.com/uploadOnly', {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const res = await response.json();
        //console.log(res);
        setData({
          ...data, addressImageUrl: res.fileUrl
        });
      } catch (error) {
        console.error('Error:', error);
      } finally {
        reader.onload = (event) => {
          setFilePreview(event.target.result);
        };
        reader.readAsDataURL(selectedFile);
      }
    }
  }

  const handleChoiceChange = (choice) => {
    setData({ ...data, step3Option: choice });
    setSelectedChoice(choice);
    saveChoiceToStorageStep3(selectedChoice);
  };


  //const isNextButtonEnabled = selectedChoice && file2;
  const isNextButtonEnabled = selectedChoice;

  const handleNextClick = () => {
    if (isNextButtonEnabled) {
      saveChoiceToStorageStep3(selectedChoice);
      nextStep();
    } else {
      alert("Please complete all fields and upload a file before proceeding.");
    }
  }



  return (
    <div className="container">
      <form className="step3-form">
        <div className="descriptions">
          <p className="description-text">居住情況</p>
        </div>

        <div className="radio-option1">
          <input type="radio" id="option1" name="radio-group" value="自置物業" checked={selectedChoice === "自置物業"}
            onChange={() => handleChoiceChange("自置物業")} />
          <label htmlFor="option1" className="radio-label">自置物業</label>
        </div>

        <div className="radio-option2">
          <input type="radio" id="option2" name="radio-group" value="租置" checked={selectedChoice === "租置"}
            onChange={() => handleChoiceChange("租置")} />
          <label htmlFor="option2" className="radio-label">租置</label>
        </div>

        <div className="radio-option3">
          <input type="radio" id="option3" name="radio-group" value="家人物業" checked={selectedChoice === "家人物業"}
            onChange={() => handleChoiceChange("家人物業")} />
          <label htmlFor="option3" className="radio-label">家人物業</label>
        </div>

        <div className="radio-option4">
          <input type="radio" id="option4" name="radio-group" value="公屋" checked={selectedChoice === "公屋"}
            onChange={() => handleChoiceChange("公屋")} />
          <label htmlFor="option4" className="radio-label">公屋</label>
        </div>


        <div className="radio-option5">
          <input type="radio" id="option5" name="radio-group" value="居屋" checked={selectedChoice === "居屋"}
            onChange={() => handleChoiceChange("居屋")} />
          <label htmlFor="option5" className="radio-label">居屋</label>
        </div>

        <div className="radio-option6">
          <input type="radio" id="option6" name="radio-group" value="村屋" checked={selectedChoice === "村屋"}
            onChange={() => handleChoiceChange("村屋")} />
          <label htmlFor="option6" className="radio-label">村屋</label>
        </div>

        <div className="content-container">
          <div className="replay-options">
            <div className="descriptions-address">
              <p className="description-address">住址證明</p>
            </div>
            <div className="s3-upload-container">
              <input type="file" id="file-upload" accept="image/*" hidden onChange={handleFileChange} />
              <label htmlFor="file-upload" className="custom-file-upload">
                {file2 === null ? (<img src="/images/step3/upload.png" alt="Upload" />) : (<img src={filePreview} alt="Upload" />)}
              </label>
              <div id="preview-area"></div>
            </div>
          </div>

          <div className="image-container">
            <img src="/images/step1/FATY_nobg.png" alt="Descriptive Alt Text" />
          </div>

        </div>






      </form>

      <footer className="footer">
        <div className="footer-left">
          <a href="https://wa.me/97733938">
            <img src="/images/buttons/ws.png" alt="Whastapp" className="footer-button-image" />
          </a>
        </div>
        <div className="footer-right">
          <img src="/images/buttons/back.png" alt="Back" className="footer-button-image" onClick={prevStep} />
          {isNextButtonEnabled ? <img src="/images/buttons/next.png" alt="Next" className="footer-button-image" onClick={handleNextClick} /> : <img src="/images/buttons/next(dim).png" alt="Next" className="footer-button-image" />}
        </div>
      </footer>
    </div>
  );
}

export default Step3;
