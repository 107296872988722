// ChoiceStorage.js
export function saveChoiceToStorage(choice) {
  localStorage.setItem('selectedChoice', choice);
}

export function loadChoiceFromStorage() {
  return localStorage.getItem('selectedChoice');
}

export function saveChoiceToStorage2(choice) {
  localStorage.setItem('selectedChoice2', choice);
}

export function loadChoiceFromStorage2() {
  return localStorage.getItem('selectedChoice2');
}

export function saveChoiceToStorageStep3(choice) {
  localStorage.setItem('selectedChoiceStep3', choice);
}

export function loadChoiceFromStorageStep3() {
  return localStorage.getItem('selectedChoiceStep3');
}


export function saveChoiceToStorageStep4(choice) {
  localStorage.setItem('selectedChoiceStep4', choice);
}

export function loadChoiceFromStorageStep4() {
  return localStorage.getItem('selectedChoiceStep4');
}

