import React, { useState } from 'react';
import '../css/Step1.css';
import '../css/Step1Amount.css';
import '../css/Step1Replacement.css';
import { saveChoiceToStorage, loadChoiceFromStorage, saveChoiceToStorage2, loadChoiceFromStorage2 } from '../storage/ChoiceStorage';
import TopButton from './TopButton';
import TopButton2 from './TopButton2';


function Step1({ nextStep, data, setData ,backToStart , jumpToMortage}) {


  const [selectedChoice, setSelectedChoice] = useState(loadChoiceFromStorage() || '');
  const [selectedChoice2, setSelectedChoice2] = useState(loadChoiceFromStorage2() || '');

  const handleChoiceChange = (e) => {
    setData({ ...data, step1Option: e });
    setSelectedChoice(e);
  };

  const handleChoiceChange2 = (choice) => {
    setData({ ...data, step1Option2: choice });
    setSelectedChoice2(choice);
  };

  const isNextButtonEnabled = selectedChoice !== '' && selectedChoice2 !== '';
  const handleNext = () => {
    if (isNextButtonEnabled) {
      saveChoiceToStorage(selectedChoice);
      saveChoiceToStorage2(selectedChoice2);
      nextStep();
    } else {
      alert('Please select an option before proceeding.');
    }
  };

  return (

    <div className="containerS1">


      <section className="avatars">
        <img src="/images/step1/FATY.png" alt="Avatar 1" className="avatar" />
        <img src="/images/step1/HOMY.png" alt="Avatar 2" className="avatar" />
      </section>

      {data.appType === "PLoan"   && <TopButton nextStep={nextStep} data={data} setData={setData} jumpToMortage={jumpToMortage}/> }
      {data.appType === "Mortgage"   && <TopButton2 nextStep={nextStep} data={data} setData={setData} backToStart={backToStart}/> }
      <div className="container">

      <form className="radio-form">
        <div className='options'>
          <div className="descriptions">
            <p className="description-text">私人貸款</p>
          </div>

          <div className="additional-text">
            <p>申請金額</p>
          </div>

          <div className="radio-option1">
            <input type="radio" id="option1" name="radio-group" value="20000" checked={selectedChoice === "20000"}
              onChange={() => handleChoiceChange("20000")} />
            <label htmlFor="option1" className="radio-label">$20,000月供$800</label>
          </div>

          <div className="radio-option2">
            <input type="radio" id="option2" name="radio-group" value="40000" checked={selectedChoice === "40000"}
              onChange={() => handleChoiceChange("40000")} />
            <label htmlFor="option2" className="radio-label">$40,000月供$1600</label>
          </div>

          <div className="radio-option3">
            <input type="radio" id="option3" name="radio-group" value="60000" checked={selectedChoice === "60000"}
              onChange={() => handleChoiceChange("60000")} />
            <label htmlFor="option3" className="radio-label">$60,000月供$2400</label>
          </div>

          <div className="radio-option4">
            <input type="radio" id="option4" name="radio-group" value="80000" checked={selectedChoice === "80000"}
              onChange={() => handleChoiceChange("80000")} />
            <label htmlFor="option4" className="radio-label">$80,000月供$3200</label>
          </div>


          <div className="radio-option5">
            <input type="radio" id="option5" name="radio-group" value="100000" checked={selectedChoice === "100000"}
              onChange={() => handleChoiceChange("100000")} />
            <label htmlFor="option5" className="radio-label">$100,000月供$4000</label>
          </div>

          <div className="radio-option6">
            <input type="radio" id="option6" name="radio-group" value="120000" checked={selectedChoice === "120000"}
              onChange={() => handleChoiceChange("120000")} />
            <label htmlFor="option6" className="radio-label">$120,000月供$4800</label>
          </div>

          <div className="radio-option7">
            <input type="radio" id="option7" name="radio-group" value="150000" checked={selectedChoice === "150000"}
              onChange={() => handleChoiceChange("150000")} />
            <label htmlFor="option7" className="radio-label">$150,000月供$6000</label>
          </div>

        </div>
        <div className="descriptions2">
          <p className="description2-text">還款期</p>
        </div>

        <div className="content-container2">
          <div className="child-div replay-options1">
            <div className="radio-optionR1">
              <input type="radio" id="optionR1" name="replay" value="12" checked={selectedChoice2 === "12"}
                onChange={() => handleChoiceChange2("12")} />
              <label htmlFor="optionR1" className="replay-label">12期</label>
            </div>
            <div className="radio-optionR2">
              <input type="radio" id="optionR2" name="replay" value="24" checked={selectedChoice2 === "24"}
                onChange={() => handleChoiceChange2("24")} />
              <label htmlFor="optionR2" className="replay-label">24期</label>
            </div>
            <div className="radio-optionR3">
              <input type="radio" id="optionR3" name="replay" value="36" checked={selectedChoice2 === "36"}
                onChange={() => handleChoiceChange2("36")} />
              <label htmlFor="optionR3" className="replay-label">36期</label>
            </div>
            <div className="radio-optionR4">
              <input type="radio" id="optionR4" name="replay" value="48" checked={selectedChoice2 === "48"}
                onChange={() => handleChoiceChange2("48")} />
              <label htmlFor="optionR4" className="replay-label">48期</label>
            </div>
            <div className="radio-optionR5">
              <input type="radio" id="optionR5" name="replay" value="60" checked={selectedChoice2 === "60"}
                onChange={() => handleChoiceChange2("60")} />
              <label htmlFor="optionR5" className="replay-label">60期</label>
            </div>
          </div>

          <div className="child-div image-container1">
            <img src="/images/step1/berry.png" alt="Descriptive Alt Text" />
          </div>
        </div>

        <div className="terms">
          <p>以上供款金額為淨利息</p>
        </div>
      </form>

      <footer className="footer">
        <div className="footer-left">
          <a href="https://wa.me/97733938">
            <img src="/images/buttons/ws.png" alt="Whastapp" className="footer-button-image" />
          </a>
        </div>
        <div className="footer-right">
          {isNextButtonEnabled ? <img src="/images/buttons/next.png" alt="Next" className="footer-button-image" onClick={handleNext} /> : <img src="/images/buttons/next(dim).png" alt="Next" className="footer-button-image" />}
        </div>
      </footer>
    </div>
    </div >

  );
}

export default Step1;
