/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2023-11-15 21:24:41
 * @LastEditTime: 2023-12-04 17:32:23
 * @Description: 
 */
import React, { useState } from 'react';
import './App.css';
import Step1 from './component/Step1';
import Step2 from './component/Step2';
import Step3 from './component/Step3';
import Step4 from './component/Step4';
import Step5 from './component/Step5';
import Step6 from './component/Step6';
import Popup from './component/Popup';
import Submited from './component/Step1';
import Mortage from './component/Mortage';
import Timer from './component/Timer'; // Import the Timer component
import { FileProvider } from './storage/FileContext';
import { saveChoiceToStorage, saveChoiceToStorage2 } from './storage/ChoiceStorage';
import './css/Timer.css';


function App() {


  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    appType: 'PLoan',
    name: '',
    nameEn: '',
    birthDate: '',
    age: '',
    idNumber: '',
    sex: '',
    phoneNumber: '',
    bank: '',
    step1Option: '',
    step3Option: '',
    step4Option: '受僱',
    step4Option2: '否',
    step1Option2: '',
    companyIncome: '',
    companyName: '',
    companyPhone: '',
    companyPosition: '',
    idImageUrl: '',
    addressImageUrl: '',
    inComeImageUrl: '',
    mortageOption:'',
    mortageYear:'',
    mortageImageUrl: '',

    // Other form fields
  });

  const clearLocalStorage = () => {
    // Clear specific items from localStorage
    localStorage.clear();
    localStorage.removeItem('selectedChoice');
    localStorage.removeItem('selectedChoice2');
    saveChoiceToStorage2('');
    saveChoiceToStorage('');
    //localStorage.removeItem('imagePreview');
    //localStorage.removeItem('selectedChoiceStep3');
    //localStorage.removeItem('uploadedImage');
    //localStorage.removeItem('uploadedImageStep3');
  };
  window.addEventListener('beforeunload', clearLocalStorage);

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const backToStart = () => {
    setCurrentStep(1);
  };

  const jumpToMortage = () => {
    setCurrentStep(11);
  };

  // Render the appropriate step based on the currentStep
  let stepComponent;
  switch (currentStep) {
    case 1:
      stepComponent = <Step1 nextStep={nextStep} data={formData} setData={setFormData} backToStart={backToStart} jumpToMortage={jumpToMortage}/>;
      break;
    case 2:
      stepComponent = <Step2 nextStep={nextStep} data={formData} setData={setFormData} prevStep={prevStep} />;
      break;
    case 3:
      stepComponent = <Step3 nextStep={nextStep} data={formData} setData={setFormData} prevStep={prevStep} />;
      break;
    case 4:
      stepComponent = <Step4 nextStep={nextStep} data={formData} setData={setFormData} prevStep={prevStep} />;
      break;
    case 5:
      stepComponent = <Step5 nextStep={nextStep} data={formData} setData={setFormData} prevStep={prevStep} />;
      break;
    case 6:
      stepComponent = <Step6 nextStep={nextStep} data={formData} setData={setFormData} prevStep={prevStep} />;
      //stepComponent = <FinalStep data={formData} nextStep={nextStep} prevStep={prevStep} />;
      break;
    case 7:
      stepComponent = <Submited data={formData} />;
      break;
    case 11:
      stepComponent = <Mortage data={formData} setData={setFormData}  backToStart={backToStart}/>;
      break;
    default:
    // Handle any other case as needed
  }


  return (
    <FileProvider>
      <div className="App">
        <div className="logo-area">
          <div className="logo-container">
            <img src="/images/logo.png" alt="Logo" className="logo" />
          </div>
          <div className='timer'>
            <div className="top-right-corner">
              <Timer />
            </div>
          </div>
          <Popup />
        </div>

        {stepComponent}

        <div className="text-links-section">
          <p><a href="your-link-url-1">法律條文</a></p>
          <p><a href="your-link-url-1">法律聲明</a></p>
          <p><a href="your-link-url-1">放債人條例</a></p>
          <p><a href="your-link-url-1">私隱政策</a></p>
          <p><a href="your-link-url-1">個人資料收集聲明</a></p>
        </div>
        <div className="info">
          <p className="address"><a href="https://www.google.com.hk/maps/search/%E4%B9%9D%E9%BE%8D%E5%B0%96%E6%B2%99%E5%92%80%E5%BB%A3%E6%9D%B1%E9%81%9328%E8%99%9F%E6%96%B0%E5%A4%AA%E9%99%BD%E5%BB%A3%E5%A0%B4/@22.2966559,114.1670267,17z/data=!3m1!4b1?hl=zh-TW&entry=ttu" target="_blank" rel="noreferrer">九龍尖沙咀廣東道28號新太陽廣場903-905室<br />(GUCCI 專門店對面)</a></p>
          <p className="phone"><a href="tel:23777777">電話： 23777777</a></p>
          <p className="web">本網站資料僅供參考並會不時更新</p>
          <p className="web">本公司保留最終法律决定權</p>
          <p className="warning">忠告：借錢緊要還，咪俾錢中介</p>
          <p className="web">放債人牌照號碼：0513/2023</p>
          <p className="web">2023 光速信貸快遞版權所有</p>
        </div>
      </div>
    </FileProvider >
  );
}

export default App;
